import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { SelectFieldFromApi } from './SelectFieldFromApiComponentExtension';


export const SelectFieldFromApiExtension = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'SelectFieldFromApi',
      component: SelectFieldFromApi,
    }),
  );