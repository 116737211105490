import {
    createBaseThemeOptions,
    createUnifiedTheme,
    genPageTheme,
    palettes,
    shapes,
  } from '@backstage/theme';
  
  export const afyaTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes.light,
        primary: {
          main: '#333333',
        },
        secondary: {
          main: '#0057B8',
        },
        error: {
          main: '#1A2141',
        },
        warning: {
          main: '#265AA6',
        },
        info: {
          main: '#0057B8',
        },
        success: {
          main: '#265AA6',
        },
        background: {
          default: '#FFFFFF',
          paper: '#EEEEEE',
        },
        banner: {
          info: '#0057B8',
          error: '#1A2141',
          text: '#333333',
          link: '#0057B8',
        },
        errorBackground: '#1A2141',
        warningBackground: '#265AA6',
        infoBackground: '#265AA6',
        navigation: {
          background: '#333333',
          indicator: '#EEEEEE',
          color: '#EEEEEE',
          selectedColor: '#EEEEEE',
          submenu: {
            background: '#333333',
          },
        },
      },
    }),
    defaultPageTheme: 'home',
    typography: {
        htmlFontSize: 16,
        fontFamily: 'Montserrat, sans-serif',
        h1: {
          fontSize: 54,
          fontWeight: 700,
          marginBottom: 10,
        },
        h2: {
          fontSize: 40,
          fontWeight: 700,
          marginBottom: 8,
        },
        h3: {
          fontSize: 32,
          fontWeight: 700,
          marginBottom: 6,
        },
        h4: {
          fontWeight: 700,
          fontSize: 28,
          marginBottom: 6,
        },
        h5: {
          fontWeight: 700,
          fontSize: 24,
          marginBottom: 4,
        },
        h6: {
          fontWeight: 700,
          fontSize: 20,
          marginBottom: 2,
        },
    },
    pageTheme: {
      home: genPageTheme({ colors: ['#CE0058', '#0057B8'], shape: shapes.wave }),
      documentation: genPageTheme({
        colors: ['#CE0058', '#0057B8'],
        shape: shapes.wave2,
      }),
      tool: genPageTheme({ colors: ['#CE0058', '#0057B8'], shape: shapes.round }),
      service: genPageTheme({
        colors: ['#CE0058', '#0057B8'],
        shape: shapes.wave,
      }),
      website: genPageTheme({
        colors: ['#CE0058', '#0057B8'],
        shape: shapes.wave,
      }),
      library: genPageTheme({
        colors: ['#CE0058', '#0057B8'],
        shape: shapes.wave,
      }),
      other: genPageTheme({ colors: ['#CE0058', '#0057B8'], shape: shapes.wave }),
      app: genPageTheme({ colors: ['#CE0058', '#0057B8'], shape: shapes.wave }),
      apis: genPageTheme({ colors: ['#CE0058', '#0057B8'], shape: shapes.wave }),
    },
  });