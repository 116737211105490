import React from 'react';
import { makeStyles } from '@material-ui/core';

const useDefaultStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#D40054',
  },
});

interface LogoFullProps {
  classes?: {
    svg?: string;
    path?: string;
  };
}

const LogoFull: React.FC<LogoFullProps> = ({ classes }) => {
  const defaultClasses = useDefaultStyles();
  const appliedClasses = {
    svg: classes?.svg || defaultClasses.svg,
    path: classes?.path || defaultClasses.path,
  };

  return (
    <svg
      className={appliedClasses.svg}
      width="240"
      height="101"
      viewBox="0 0 240 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={appliedClasses.path}
        d="M68.38 0C64.55 0 62.16 1.57 59.93 4.54C44.93 24.43 0 84.03 0 84.03C0 84.03 8.83 84.03 11.77 84.03C18.64 84.03 23.3 81.44 31.64 75.52C38.57 70.6 46.75 64.26 53.67 59.92C62.83 54.18 69.1 52.81 72.51 57.98C82.09 72.49 83.15 75.45 89.07 75.45C91.28 75.45 97.9 75.45 97.9 75.45L76.2 0C76.2 0 70.34 0 68.38 0ZM71.99 51.28C66.35 48.22 59.25 49.93 50.74 54.14C42.18 58.38 32.18 65.16 20.8 72.19L68.38 9.21L83.84 63.57C79.14 58.24 75.02 52.92 71.99 51.28Z"
      />
      <path
        className={appliedClasses.path}
        d="M136.389 23.8498H121.629C121.629 23.8498 122.199 19.9698 122.389 18.6798C122.889 15.3198 123.919 12.8298 125.479 11.2398C127.039 9.63977 129.539 8.83977 132.989 8.83977C134.379 8.83977 138.539 8.83977 138.539 8.83977L142.979 0.00976562C142.979 0.00976562 135.959 0.00976562 133.619 0.00976562C126.889 0.00976562 121.839 1.45977 118.479 4.35977C115.109 7.25977 113.009 12.0798 112.049 18.8098C111.869 20.0698 111.289 23.8598 111.289 23.8598H102.459L101.199 32.0598H110.279L104.219 75.4598H114.439L120.499 32.0598H135.259L136.389 23.8498Z"
      />
      <path
        className={appliedClasses.path}
        d="M187.871 23.8501C185.351 23.8501 182.991 25.3601 181.811 27.8901L162.511 67.6301L152.041 23.8501H141.691L154.691 75.0801C155.031 76.3401 155.191 77.6401 155.191 78.9901C155.191 81.1801 154.791 83.2601 153.491 85.2401C152.181 87.2201 150.361 88.9401 148.001 90.1601C145.641 91.3801 142.701 91.8601 139.671 91.8601H134.941L130.391 100.69H139.921C144.971 100.69 148.921 100.04 151.781 98.7301C154.641 97.4201 156.991 95.4901 158.851 92.9301C160.701 90.3601 163.011 86.3401 165.791 80.8801C173.061 66.6101 194.941 23.8501 194.941 23.8501H187.871Z"
      />
      <path
        className={appliedClasses.path}
        d="M221.06 23.8501H203.52L199.61 32.0501H219.42C222.95 32.0501 225.54 32.7001 227.18 34.0101C228.82 35.3201 229.64 37.5201 229.64 40.6301C229.64 41.1301 229.47 43.1301 229.35 43.9101C225.35 43.9101 211.06 43.9101 211.06 43.9101C205.68 43.9101 201.24 45.0501 197.75 47.3201C194.26 49.5901 192.18 53.2501 191.5 58.3001C191.33 59.6501 191.25 60.5701 191.25 61.0801C191.25 65.7101 192.81 69.4901 195.92 72.4401C199.03 75.3901 203.03 76.8601 207.91 76.8601C214.55 76.8601 221.14 73.7501 226.61 67.5201C226.61 67.5201 226.61 67.5201 226.61 67.5101L226.98 75.4601H235.18C235.18 75.4601 238.2 53.6901 239.22 46.3901C239.47 44.5501 239.85 41.8701 239.85 39.5001C239.86 29.0701 233.59 23.8501 221.06 23.8501ZM218 66.0501C215.05 67.4401 212.36 68.1301 209.93 68.1301C207.57 68.1301 205.53 67.4801 203.81 66.1701C202.08 64.8701 201.22 63.0401 201.22 60.6801C201.22 57.6501 202.12 55.4701 203.93 54.1201C205.74 52.7801 208.07 52.1001 210.93 52.1001H228.25L227.11 60.3001C224.34 62.7501 220.94 64.6701 218 66.0501Z"
      />
    </svg>
  );
};

export default LogoFull;