import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  pathPink: {
    fill: '#D40054',
  },
});

const LogoIcon: React.FC = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      width="98"
      height="85"
      viewBox="0 0 98 85"
    >
      <path
        className={classes.pathPink}
        d="M68.38 0C64.55 0 62.16 1.57 59.93 4.54C44.93 24.43 0 84.03 0 84.03C0 84.03 8.83 84.03 11.77 84.03C18.64 84.03 23.3 81.44 31.64 75.52C38.57 70.6 46.75 64.26 53.67 59.92C62.83 54.18 69.1 52.81 72.51 57.98C82.09 72.49 83.15 75.45 89.07 75.45C91.28 75.45 97.9 75.45 97.9 75.45L76.2 0C76.2 0 70.34 0 68.38 0ZM71.99 51.28C66.35 48.22 59.25 49.93 50.74 54.14C42.18 58.38 32.18 65.16 20.8 72.19L68.38 9.21L83.84 63.57C79.14 58.24 75.02 52.92 71.99 51.28Z"
      />
    </svg>
  );
};

export default LogoIcon;