import { z } from 'zod';

export type OAuthConfig = {
  provider: 'microsoft' | 'github' | 'google' | 'bitbucket';
  scopes: string[];
};

export const selectFieldFromApiConfigSchema = z.object({
  params: z
    .record(z.string(), z.string())
    .optional()
    .or(z.array(z.record(z.string(), z.string())).optional()),
  path: z.string(),
  arraySelector: z.string().or(z.array(z.string())).optional(),
  valueSelector: z.string().or(z.array(z.string())).optional(),
  labelSelector: z.string().or(z.array(z.string())).optional(),
  title: z.string().optional(),
  placeholder: z.string().optional(),
  description: z.string().optional(),
  oauth: z
    .object({
      provider: z.enum(['microsoft', 'github', 'bitbucket', 'google']),
      scopes: z.array(z.string()),
    })
    .optional(),
});